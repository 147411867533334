<template>
  <b-card class="h-100">
    <div class="mb-2 d-flex justify-content-between overfow overflow-hidden w-100">
      <h4 class="text-nowrap text-truncate mr-2">{{ `${$t('Uploads')} (${$t('Sales')})` }}</h4>
      <feather-icon
        class="cursor-pointer position-absolute"
        style="top: 1.4rem; right: 1.4rem"
        :icon="collapsed ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'"
        size="22"
        @click="handleCollapse"
      />
    </div>
    <b-dropdown
      :text="$t('New Import')"
      variant="primary"
      class="w-100"
      menu-class="w-100 text-center overflow-hidden text-nowrap text-truncate"
    >
      <b-dropdown-item @click="handleImportTable(tableNames[0])">
        <span>{{ getTableName(tableNames[0]) }}</span>
      </b-dropdown-item>
      <b-dropdown-item @click="handleImportTable(tableNames[1])">
        {{ getTableName(tableNames[1]) }}
      </b-dropdown-item>
      <b-dropdown-item @click="handleImportTable(tableNames[2])">
        {{ getTableName(tableNames[2]) }}
      </b-dropdown-item>
    </b-dropdown>
    <input
      ref="xlsxExplorer"
      type="file"
      class="d-none"
      accept=".xlsx"
      @change="e => handleImportFile('xlsx', e)"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="flex: 1"
      class="d-flex flex-column"
    >
      <b-table
        ref="refTable"
        fixed
        bordered
        :busy="loading"
        :items="pageItems"
        responsive
        :fields="visibleFields"
        primary-key="id"
        show-empty
        :empty-text="$t('No imports found')"
        class="table-responsive mt-2"
      >
        <template #head()="{ label }">
          <div class="table-header">
            {{ $t(label) }}
          </div>
        </template>
        <template #cell(fileName)="{ item }">
          <div class="table-cell">
            <b-link
              :id="`fileName-${item.id}`"
              class="font-weight-bold bd-highlight"
              @click="selectImport(item)"
            >
              <span>
                {{ item.fileName }}
              </span>
            </b-link>
          </div>
          <b-tooltip :target="`fileName-${item.id}`">
            {{ item.fileName }}
          </b-tooltip>
        </template>
        <template #cell(tableName)="{ item }">
          <div
            :id="`tableName-${item.id}`"
            class="table-cell"
          >
            <span>
              {{ getTableName(item.tableName) }}
            </span>
          </div>
          <b-tooltip :target="`tableName-${item.id}`">
            {{ getTableName(item.tableName) }}
          </b-tooltip>
        </template>
        <template #cell(jobStarted)="{ item }">
          <div
            :id="`jobStarted-${item.id}`"
            class="table-cell"
          >
            <span>
              {{ item.jobStarted | moment }}
            </span>
          </div>
          <b-tooltip :target="`jobStarted-${item.id}`">
            {{ item.jobStarted | moment }}
          </b-tooltip>
        </template>
      </b-table>
    </b-overlay>
    <b-pagination
      :value="page"
      :total-rows="allItemsCount"
      :per-page="size"
      first-number
      align="end"
      last-number
      hide-goto-end-buttons
      class="mb-n2 mt-1 mt-sm-0 align-self-end"
      prev-class="prev-item"
      next-class="next-item"
      @input="handleCurrentPage"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="20"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="20"
        />
      </template>
    </b-pagination>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import imexService from '@/services/imex'
import momentFilterMixin from '@/mixins/momentFilter'

export default {
  components: {
    BCard,
    BTable,
  },
  mixins: [momentFilterMixin],
  props: {
    products: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      collapsed: false,
      loading: false,
      importing: false,
      name: 'Sales',
      schemaName: 'custom_schema',
      entityType: 'salg',
      tableNames: ['salg', 'salg_fg', 'salg_et_400'],
      status: 'COMPLETED',
      page: 1,
      size: 10,
      allItemsCount: 0,
      filteredItemsCount: 0,
      pageItems: [],
    }
  },
  computed: {
    visibleFields() {
      return !this.collapsed
        ? [
            { key: 'fileName', label: 'Name' },
            { key: 'tableName', label: 'Product Name' },
            { key: 'jobStarted', label: 'Date' },
          ]
        : [{ key: 'fileName', label: 'Name' }]
    },
  },
  mounted() {
    this.getImports(0)
  },
  methods: {
    handleImportTable(value) {
      this.entityType = value
      this.$refs.xlsxExplorer.click()
    },
    handleCollapse() {
      this.collapsed = !this.collapsed
      this.$emit('collapse', this.collapsed)
    },
    handleCurrentPage(value) {
      // Adjusting the page index for API calls (assuming 0-based index)
      const pageIndex = value

      // Only fetch new imports if the page index has changed
      if (pageIndex !== this.page) {
        this.page = pageIndex
        this.getImports(pageIndex - 1)
      }
    },
    async getImports(pageNum) {
      this.loading = true

      try {
        const { response } = await this.$async(
          imexService.getCustomActiveImports(
            { ...this.$lodash.pick(this, ['schemaName', 'tableNames', 'status']) },
            {
              page: pageNum,
              size: this.size,
            },
          ),
        )
        this.allItemsCount = response.data.allItemsCount
        this.filteredItemsCount = response.data.filteredItemsCount
        this.pageItems = response.data.pageItems
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    getTableName(table) {
      const tables = {
        salg: 'Salg EP/EN - 603/467',
        salg_fg: 'Salg Forsikringsgrad',
        salg_et_400: 'Salg ET 400',
      }
      return tables[table]
    },

    showNotice(io) {
      this.$swal({
        title: this.$t(`imex-action-${io}-notice`),
        icon: 'info',
        confirmButtonText: this.$t('Close'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
    },

    async handleImportFile(type, e) {
      const { files } = e.target
      if (!files.length) return

      const [file] = files
      const formData = new FormData()
      formData.append('file', file)

      try {
        await this.$async(imexService.initiateImport({ ...this.$lodash.pick(this, ['schemaName', 'entityType']) }, formData))

        this.showNotice('import')
        this.$refs[`${type}Explorer`].value = null
      } catch (error) {
        console.log(error)
      }
    },

    selectImport(item) {
      const { id, tableName } = this.$route.params
      if (item.id === id && item.tableName === tableName) return

      try {
        this.$router.replace({
          params: {
            id: item.id,
            tableName: item.tableName,
          },
        })
      } catch (error) {
        console.log('')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-cell {
  overflow: hidden;
  text-align: center;
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
