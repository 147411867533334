<template>
  <b-row>
    <b-col
      :lg="collapsed ? 2 : 4"
      style="transition: ease-in-out 0.3s"
    >
      <imports-list
        ref="importsList"
        @collapse="collapsed = $event"
      />
    </b-col>
    <b-col
      :lg="collapsed ? 10 : 8"
      style="transition: ease-in-out 0.3s"
    >
      <import-details-list :products="products" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ImportsList from './ImportsList.vue'
import { customEntityRowsService } from '@/services/customEntityData'
import ImportDetailsList from './ImportDetailsList.vue'

export default {
  components: {
    BRow,
    BCol,
    ImportsList,
    ImportDetailsList,
  },
  data() {
    return {
      collapsed: false,
      products: [],
    }
  },

  mounted() {
    this.getProducts()
  },

  methods: {
    async getProducts() {
      this.loading = true
      try {
        const { data } = await customEntityRowsService('model_product').getAll()
        if (data) {
          console.log(data)
          this.products = data.pageItems.map(item => item.values)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/master-variables.scss';

.max-w-200px {
  max-width: 200px;
}

.prozess-inquiry {
  span {
    white-space: nowrap;
  }

  &__table {
    .b-table-sticky-column {
      left: unset !important;
      right: 0 !important;
    }
    td.b-table-sticky-column {
      background: var(--colour--table-row) !important;
    }
  }
}

.prozess-inquiry__dropdown {
  .vs--open {
    div > div > .vs__selected {
      margin-top: 10px !important;
    }
  }
  div > div > div.vs__selected-options {
    height: 35px;
    overflow: hidden;
    width: 140px;
    .vs__selected {
      margin-top: 0px;
      height: 100%;
    }
  }
}
</style>
