<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="flex: 1"
      class="d-flex flex-column"
    >
      <b-card v-if="$route?.params.id && $route?.params.tableName">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <b-button
              :disabled="pageItems.length === 0"
              variant="primary"
              @click="handleContacts"
            >
              {{ $t('Contacts') }}
            </b-button>
          </div>
          <div class="d-flex">
            <b-button
              id="delete-import-button"
              variant="light"
              class="btn-icon mr-1"
              @click="deleteImport"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip target="delete-import-button">
              {{ $t('Delete') }}
            </b-tooltip>
            <div v-if="type !== 'duplicates'">
              <b-button
                id="duplicates-import-button"
                variant="light"
                class="btn-icon mr-1"
                @click="() => handleTypeSelect('duplicates')"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
              <b-tooltip target="duplicates-import-button">
                {{ $t('Duplicates') }}
              </b-tooltip>
            </div>
            <div v-else>
              <b-button
                id="duplicates-import-button"
                variant="light"
                class="btn-icon mr-1"
                @click="() => handleTypeSelect('all')"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-tooltip target="duplicates-import-button">
                {{ $t('All') }}
              </b-tooltip>
            </div>
            <div v-if="type !== 'orphans'">
              <b-button
                id="salesWithoud-button"
                :disabled="pageItems.length === 0"
                variant="light"
                class="btn-icon mr-1"
                @click="() => handleTypeSelect('orphans')"
              >
                <feather-icon icon="UserMinusIcon" />
              </b-button>
              <b-tooltip target="salesWithoud-button">
                {{ $t('Sales Without Seller') }}
              </b-tooltip>
            </div>
            <div v-else>
              <b-button
                id="orphans-button"
                variant="light"
                class="btn-icon mr-1"
                @click="() => handleTypeSelect('all')"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-tooltip target="orphans-button">
                {{ $t('All') }}
              </b-tooltip>
            </div>
            <b-button
              id="reassign-import-button"
              variant="light"
              class="btn-icon"
              disabled
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </div>
        </div>
        <b-table
          :busy="loading"
          :items="pageItems"
          responsive
          :fields="fields"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive mt-2"
        >
          <template #head()="{ label }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ $t(label) }}</span>
            </div>
          </template>
          <template #cell(product)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ getProductLabel(item.values.product) }}</span>
            </div>
          </template>
          <template #cell(car_brand)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.values.car_brand }}</span>
            </div>
          </template>
          <template #cell(car_model)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.values.car_model }}</span>
            </div>
          </template>
          <template #cell(chassis_number)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.values.chassis_number }}</span>
            </div>
          </template>
          <template #cell(new_car)="{ item }">
            <div class="w-100 text-center">
              <feather-icon
                v-if="item.values.new_car"
                icon="CheckCircleIcon"
                size="16"
              />
              <feather-icon
                v-else
                icon="XCircleIcon"
                size="16"
              />
            </div>
          </template>
          <template #cell(week)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.values.week }}</span>
            </div>
          </template>
          <template #cell(date)="{ item }">
            <div class="w-100 text-center">
              <span class="text-nowrap">{{ item.values.date | moment }}</span>
            </div>
          </template>
          <template #cell(seller)="{ item }">
            <div class="w-100 text-center">
              <b-link
                :id="`fileName-${item.id}`"
                class="font-weight-bold bd-highlight text-nowrap"
                @click="handleContactSelect(item.values)"
              >
                {{ item.values.seller }}
              </b-link>
            </div>
          </template>
          <!-- START: Actions Column -->
          <template #cell(manage)="{ item }">
            <div class="d-flex align-items-center justify-content-center">
              <!-- Edit Button -->
              <feather-icon
                :id="`${item.values.uuid_key}-edit`"
                class="mx-1 cursor-pointer"
                icon="EditIcon"
                size="16"
                @click="openModal(item.values)"
              />
              <b-tooltip
                :target="`${item.values.uuid_key}-edit`"
                :title="$t('Edit')"
                placement="top"
              />
              <!-- History Button -->
              <feather-icon
                :id="`${item.values.uuid_key}-history`"
                size="16"
                icon="ClockIcon"
                class="mx-1 cursor-pointer"
                @click="() => openHistoryModal(item.values)"
              />
              <b-tooltip
                :target="`${item.values.uuid_key}-history`"
                :title="$t('History')"
                placement="top"
              />
            </div>
          </template>
          <!-- END: Action Column -->
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="allItemsCount"
          :per-page="size"
          first-number
          align="end"
          last-number
          class="mt-1 mt-sm-0 align-self-end"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
            />
          </template>
        </b-pagination>
      </b-card>
    </b-overlay>
    <AddSaleModal
      ref="saleModal"
      @onSave="onSave"
    />
    <history-modal ref="historyModal" />
  </div>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import momentFilterMixin from '@/mixins/momentFilter'
import AddSaleModal from './AddSaleModal.vue'
import salesService from '@/services/sales'
import HistoryModal from './HistoryModal.vue'

export default {
  components: {
    AddSaleModal,
    BCard,
    BTable,
    HistoryModal,
  },
  mixins: [momentFilterMixin],
  props: {
    products: {
      type: Array,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      duplicates: false,
      salesWithoutSellers: false,
      page: 1,
      size: 10,
      type: 'all',
      tableName: null,
      allItemsCount: 0,
      filteredItemsCount: 0,
      pageItems: [],
      fields: [
        { key: 'product', label: 'Product' },
        { key: 'car_brand', label: 'Car Brand' },
        { key: 'car_model', label: 'Model Name' },
        { key: 'chassis_number', label: 'Chassis Number' },
        { key: 'new_car', label: 'New Car' },
        { key: 'week', label: 'Sale Week' },
        { key: 'date', label: 'Sale Date' },
        { key: 'seller', label: 'Seller' },
        { key: 'manage', label: 'Manage' },
      ],
    }
  },
  watch: {
    '$route.params': {
      handler(newParams, oldParams) {
        if (newParams && newParams.tableName && newParams.id !== oldParams?.id) {
          this.getImportDetails(this.type)
        }
      },
    },
    page: {
      handler(oldPage, newPage) {
        if (oldPage === newPage || !newPage) return
        this.getImportDetails(this.type)
      },
      immediate: true,
    },
    type: {
      handler(oldType, newType) {
        if (oldType === newType) return
        this.getImportDetails(this.type)
      },
      immediate: true,
    },
  },
  methods: {
    onSave() {
      this.getImportDetails()
    },
    handleTypeSelect(type) {
      this.page = 1
      this.type = type
    },
    handleContactSelect(seller) {
      this.$router.push({
        name: 'seller',
        params: {
          name: seller.seller,
          id: this.$route.params.id,
          tableName: this.$route.params.tableName,
          contactId: seller.contact_uuid,
        },
      })
    },
    getProductLabel(productId) {
      if (this.products?.length) {
        const product = this.products.find(p => p.code === productId)
        if (product) return product.name
      }
    },
    handleSave() {
      this.getImportDetails('all')
    },
    handleContacts() {
      this.$router.push({
        name: 'sales-contacts',
        params: {
          id: this.$route.params.id,
          tableName: this.$route.params.tableName,
        },
      })
    },

    async getImportDetails(type = 'all') {
      const { id, tableName } = this.$route.params
      if (!id || !tableName) return

      this.duplicates = false
      this.salesWithoutSellers = false
      this.loading = true

      const request = type === 'all' ? salesService.getAllSalesByJob : type === 'duplicates' ? salesService.getDuplicates : salesService.getSalesWithoutSellers

      const params = {
        size: this.size,
        page: this.page - 1,
      }

      try {
        const { data } = await request(id, tableName, params)

        this.allItemsCount = data.allItemsCount
        this.filteredItemsCount = data.filteredItemsCount
        this.pageItems = data.pageItems
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async deleteImport() {
      if (this.$can('Delete', 'CRM') === false) return

      const { id, tableName } = this.$route.params
      if (!id || !tableName) return

      const res = await this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to remove?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })

      if (!res.value) return

      this.loading = true

      try {
        await salesService.deleteSalesImport(id, tableName)
        this.pageItems = []
        this.$refs.importsList.pageItems = this.$refs.importsList.pageItems.filter(importItem => importItem.id !== id)
        this.$router.replace({
          name: 'sales',
          params: {
            id: undefined,
            tableName: undefined,
          },
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    openHistoryModal(item) {
      this.$refs.historyModal.openModal({ item })
    },
    openModal(item) {
      this.$refs.saleModal.openModal({ item })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/master-variables.scss';
.table-header {
  text-align: center;
}
.danger {
  color: $colour--danger;
}
.success {
  color: $colour--success;
}
</style>
